import { Box } from '../Box'
import { ComponentType, FC, useRef } from 'react'
import { InputBaseProps, InputProps } from '../../../types/atoms/input.types'
import { Text } from '../Text'
import { border, color, space, typography } from 'styled-system'
import { theme } from '../../../theme'
import styled from 'styled-components'

const InlineInputBase: ComponentType<InputBaseProps> = styled.input<InputBaseProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${props => (props.background ? props.background : 'transparent')};
  width: 100%;
  border: 1px dashed #888888;
  line-height: normal;
  outline: none;
  font-family: ${theme.fontFamily};
  ${border}
  ${typography}
    ${space}
    ${color}
`

export const InlineInput: FC<InputProps> = ({
  onChange,
  type = 'text',
  value,
  onFocus,
  label,
  helperText,
  helperTextProps,
  fontSize = '16px',
  onBlur,
  placeholder,
  height,
  width = '100%',
  disabled,
  multiline = false,
  error,
  defaultValue = '',
  id,
  inputProps,
  labelProps,
  controlled = false,
  ...rest
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const inputHeight = height || '44px'
  return (
    <Box display="block" width={width} maxWidth={rest.maxWidth}>
      {label && (
        <Text variant="body/small" {...labelProps}>
          {label}
        </Text>
      )}
      <Box height={inputHeight} position="relative" maxWidth="305px" width={width} {...rest}>
        <InlineInputBase
          onChange={onChange}
          disabled={disabled}
          onBlur={e => {
            onBlur?.(e)
          }}
          onFocus={onFocus}
          type={type}
          as={multiline ? 'textarea' : 'input'}
          placeholder={placeholder}
          fontSize={fontSize}
          id={id}
          aria-label="input-aria-label"
          ref={inputRef}
          {...(controlled ? { value } : { defaultValue: value || defaultValue })}
          {...inputProps}
        />
      </Box>
      {helperText && (
        <Text
          color={error ? 'status.errorDark' : 'primary.black'}
          variant="body/small"
          pt="4px"
          {...helperTextProps}
        >
          {helperText}
        </Text>
      )}
    </Box>
  )
}
